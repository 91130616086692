import React from 'react'
import './Mission.scss'
import { LazyLoadImage } from 'react-lazy-load-image-component';

import rocketSrc from 'src/assets/images/rocket.png'

const MissionSection = ({ mobile }) => {
    return <div className="MissionSection">
        <LazyLoadImage
            height={mobile ? 140 : 202}
            src={rocketSrc}
            alt="Apriside company's rocket"
            className="MissionSection__Image"
        />

        <h3 className="Header MissionSection__Header">
            Our mission
        </h3>

        <p className="MissionSection__Paragraph">
            Apriside is a software as a service company which offers web-based real-time operations management and optimization software for customers running complex operations. Our solutions give customers better financial control, help them to make informed decisions, manage learning processes and optimize day-to-day aspects of operations. Through the use of AI and machine learning our applications boost performance and the analytic capabilities of employees to a level not possible when using fragmented solutions.
        </p>
    </div>
}

export default MissionSection
