import React from 'react'

class ErrorBoundaryTryRenderAgain extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError() {
      return { hasError: true };
    }

    removeError = () => {
      this.setState({ hasError: false })
    }
  
    componentDidCatch(error, errorInfo) {
      setTimeout(() => {
        this.removeError()
      }, 1);
    }
  
    render() {
      if (this.state.hasError) {
        return <h1>Something went wrong.</h1>;
      }
  
      return this.props.children; 
    }
  }

export default ErrorBoundaryTryRenderAgain