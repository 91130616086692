import React from 'react'
import './Main.scss'
import Logo from 'src/components/Logo'
import Button from 'src/components/Button'
import SwipeSign from 'src/components/SwipeSign'

const MainSection = () => {
    return <div className="MainSection">        
        <Logo size='large'  className="MainSection__Logo"/>

        <h3 className="Header MainSection__Header">
            Operations <strong>efficient</strong> as never before
        </h3>

        <p className="MainSection__Paragraph">
            We make our customer's operations more efficient by releasing the full potential of their employees
        </p>

        <Button to="#products" className="MainSection__Button">
            View our products
        </Button>  

        <Button to="#about-us" variant="wrapper" className="ScrollDown">
            <SwipeSign label="Scroll down" />
        </Button>
    </div>
}

export default MainSection
