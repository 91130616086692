import React from 'react'
import './Footer.scss'
import Logo from '../Logo'
import FooterElement from './FooterElement'

import linkedinImg from 'src/assets/images/instagram.png'
import { COMPANY_LINKEDIN_URL } from 'src/constans'

const Footer = () => {
    return (
        <div className="Footer">
            <div className="Footer__LogoAndLinksWrapper">
                <Logo />

                <div className="Footer__Links">
                    <FooterElement className="Footer__Links__Element" to="#main">Home</FooterElement>
                    <FooterElement className="Footer__Links__Element" to="#about-us">About us</FooterElement>
                    <FooterElement className="Footer__Links__Element" to="#mission">Our mission</FooterElement>
                    <FooterElement className="Footer__Links__Element" to="#products">Products</FooterElement>
                    <FooterElement className="Footer__Links__Element" to="#customers">Customers</FooterElement>
                    <FooterElement className="Footer__Links__Element" to="#contact">Contact Us</FooterElement>
                </div>
            </div>

            <div className="Footer__Socials">
                <a href={COMPANY_LINKEDIN_URL}>
                    <img src={linkedinImg} alt="Apriside company's logo" />
                </a>
            </div>

            <div className="Footer__Copyright">
                © 2019 apriside.com All Rights Reserved.
            </div>
        </div>
    )
}

export default Footer
