import React from 'react'
import './Products.scss'

import productImage from 'src/assets/images/products/oos-products.svg'
import logoGeo from 'src/assets/images/products/goOS.svg'

const ProductsSection = ({ mobile }) => {
    return <div className="ProductsSection">
        <h3 className="Header ProductsSection__Header">
            Our products
        </h3>

        <div className="logo-products">
            <div className="logo-1">
                <a href="https://oilfieldos.com/" target="_blank">
                    <img src={productImage} alt="OilfieldOS Logo" className="product-logo" height="150"/>
                </a>
            </div>
            <div className="logo-2">
                <a href="https://geoenergyos.com" target="_blank">
                    <img src={logoGeo} alt="GeoenergyOS Logo" className="product-logo" height="150"/>
                </a>
            </div>
        </div>

    </div>
}

export default ProductsSection
