import React from 'react'
import classNames from 'classnames'
import './SwipeSign.scss'

const SwipeSign = ({ className, direction = 'vertical', label = 'Scroll down', gray }) => {
    return <div className={classNames("SwipeSign", {
        'SwipeSign--is-gray': gray,
        'SwipeSign--is-horizontal': direction === 'horizontal'
    }, className)}>
        <div className="SwipeSign__Border SwipeSign__Image">
            <div className="SwipeSign__Ball"></div>
        </div>
        <span className="SwipeSign__Label">{label}</span>
    </div>
}

export default SwipeSign
