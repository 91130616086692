import React from 'react'
import classNames from 'classnames'

import './Button.scss'

const Button = ({ to, children, variant = "white", size = "medium", fluid, className, onClick }) => {
    const Component = to ? "a" : "button"

    return (
        <Component
            href={to}
            onClick={onClick}
            className={classNames('Button', {
                'Button--is-wrapper': variant === 'wrapper',
                'Button--is-white': variant === "white",
                'Button--is-primary': variant === 'primary',
                'Button--is-small': size === "small",
                'Button--is-medium': size === "medium",
                'Button--is-fluid': fluid,
            }, className)}>
            {children}
        </Component>
    )
}

export default Button
