import React from 'react'
import classNames from 'classnames'

const FooterElement = ({ to, children, active, className }) => {
    return (
        <a href={to} className={classNames("FooterElement", {
            'FooterElement--is-active': active
        }, className)}>
            {children}
        </a>
    )
}

export default FooterElement
