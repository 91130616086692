import React from 'react'
import logoPng from 'src/assets/images/logo_w_big.png'
import './Logo.scss'
import classNames from 'classnames'

import { ReactComponent as LogoGray } from "src/assets/logo/logo_green_grey.inline.svg";
import { ReactComponent as LogoWhite } from "src/assets/logo/logo_white.inline.svg";

const Logo = ({ to, animated, size = "medium", className, dark }) => {
    const Comp = to ? 'a' : 'div'

    return <Comp href={to} className={classNames('Logo', { 
        "Logo--size-medium": size === 'medium',
        "Logo--size-large": size === 'large',
        "Logo--size-fluid": size === 'fluid',
        "Logo--size-small": size === 'small'
    }, className)}>
        {animated ? <>
            <LogoGray className={classNames({ 'current': dark })} />
            <LogoWhite className={classNames({ 'current': !dark })}/>
        </> : <img className={""} src={logoPng} alt="Apriside company logo" />}       
    </Comp>
}

export default Logo
