import React from 'react'
import classNames from 'classnames'

const NavbarElement = ({ to, children, active, className, dark, onClick }) => {
    return (
        <a href={to} onClick={onClick} className={classNames("NavbarElement", {
            'NavbarElement--is-active': active,
            'NavbarElement--is-dark': dark
        }, className)}>
            <span className="NavbarElement__Text">{children}</span>
        </a>
    )
}

export default NavbarElement
