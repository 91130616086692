import React from 'react'
import classNames from 'classnames'

const Section = ({ id, children, className, dataAnchor }) => {
    return <div className={classNames("section", className)} data-anchor={dataAnchor}>
        <div
            id={id}
            className="Section"
        >
            {children}
        </div>
    </div>
}

export default Section


