import React from 'react'
import './Card.scss'
import classNames from 'classnames'


const Card = ({ children, className }) => {
    return (
        <div className={classNames('Card', className)}>
            {children}
        </div>
    )
}

export default Card
