// @ts-nocheck
import React from "react"
import ReactFullpage from '@fullpage/react-fullpage';
import Section from 'src/components/Section'
import Layout from "src/components/layout"
import SEO from "src/components/seo"

import MainSection from 'src/components/sections/Main'
import AboutUsSection from 'src/components/sections/AboutUs'
import MissionSection from 'src/components/sections/Mission'
import ProductsSection from "src/components/sections/Products";
import CustomersSection from "src/components/sections/Customers/Customers";
import ContactSection from "src/components/sections/Contact";
import Footer from "src/components/Footer";
import { FULLPAGEJS_LICENSE_KEY } from "src/constans";
import Navbar from "src/components/Navbar";
import NavigationDots from "src/components/NavigationDots";
import Media from 'react-media';
import classNames from 'classnames'
import ErrorBoundaryTryRenderAgain from "src/components/ErrorBoundaryTryRenderAgain";

class IndexPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      lastDirection: null,
      currentAnchor: 'main',
      showFullPageJs: false,
      initialRender: true
    }
  }

  getCurrentBreakpoint = matches => {
    if (matches.small) return 'small'
    if (matches.medium) return 'medium'
    return 'large'
  }

  resetFullPageJS = matches => {
    if (!this.state.showFullPageJs && this.state.initialRender) {
      this.setState({ showFullPageJs: true })
    } else {
      this.setState({ showFullPageJs: false })
    }
  }

  render() {
    const { currentAnchor, lastDirection, showFullPageJs } = this.state

    return <Layout>
      <SEO title="Home" author="" />

      <Media
        queries={{
          small: "(max-width: 599px)",
          medium: "(min-width: 600px) and (max-width: 1199px)",
          large: "(min-width: 1200px)"
        }}
        onChange={this.resetFullPageJS}
      >
        {matches => (<>
          {matches.large && <NavigationDots currentAnchor={currentAnchor} />}

          <Navbar
            mobile={matches.small || matches.medium}
            currentBreakpoint={this.getCurrentBreakpoint(matches)}
            currentAnchor={currentAnchor}
            smaller={currentAnchor !== 'main'}
            dark={['about-us', 'mission', 'customers', 'contact', 'footer'].includes(currentAnchor)}
            background={['contact', 'footer'].includes(currentAnchor) && !matches.small ? 'light' : ''}
            lastDirection={lastDirection}
          />

          { showFullPageJs && <ReactFullpage
            licenseKey={FULLPAGEJS_LICENSE_KEY}
            scrollingSpeed={1000}
            anchors={['main', 'about-us', 'mission', 'products', 'customers', 'contact']}
            menu="#fullPageDots"
            loopHorizontal={true}
            continuousHorizontal={true}
            onLeave={e => { }}
            render={({ state, fullpageApi }) => {
              if (state.lastEvent === 'onLeave') {
                const nextLastDirection = state.direction
                const nextCurrentAnchor = state.destination.anchor

                if (lastDirection !== nextLastDirection && currentAnchor !== nextCurrentAnchor) {
                  this.setState({
                    lastDirection: nextLastDirection,
                    currentAnchor: nextCurrentAnchor,
                  })
                }
              }

              const mobile = matches.small

              return (<>
                <ReactFullpage.Wrapper>

                  <Section dataAnchor="main">
                    <ErrorBoundaryTryRenderAgain>
                      <MainSection />
                    </ErrorBoundaryTryRenderAgain>
                  </Section>

                  <Section dataAnchor="about-us">
                    <ErrorBoundaryTryRenderAgain>
                      <AboutUsSection mobile={matches.small || matches.medium} />
                    </ErrorBoundaryTryRenderAgain>
                  </Section>

                  <Section dataAnchor="mission">
                    <ErrorBoundaryTryRenderAgain>
                      <MissionSection mobile={mobile} />
                    </ErrorBoundaryTryRenderAgain>
                  </Section>

                  <Section dataAnchor="products">
                    <ErrorBoundaryTryRenderAgain>
                      <ProductsSection mobile={matches.small || matches.medium} />
                    </ErrorBoundaryTryRenderAgain>
                  </Section>

                  <Section dataAnchor="customers">
                    <ErrorBoundaryTryRenderAgain>
                      <CustomersSection mobile={mobile} />
                    </ErrorBoundaryTryRenderAgain>
                  </Section>

                  <Section dataAnchor="contact">
                    <ErrorBoundaryTryRenderAgain>
                      <ContactSection
                        mobile={mobile}
                        loadBg={['products', 'customers', 'contact', 'footer'].includes(currentAnchor)}
                        fullpageApi={fullpageApi}
                      />
                    </ErrorBoundaryTryRenderAgain>
                  </Section>

                  <Section dataAnchor="footer" className={classNames({ "fp-auto-height": !matches.small })}>
                    <ErrorBoundaryTryRenderAgain>
                      <Footer />
                    </ErrorBoundaryTryRenderAgain>
                  </Section>
                </ReactFullpage.Wrapper>
              </>
              );
            }}
          />}
        </>)}
      </Media>
    </Layout>
  }

}

export default IndexPage

