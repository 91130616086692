import React from 'react'
import Card from 'src/components/Card'

import './AboutUs.scss'

import improveSrc from 'src/assets/images/improve.png'
import cloudSrc from 'src/assets/images/cloud.png'
import financeSrc from 'src/assets/images/finance.png'

import SwipeSign from 'src/components/SwipeSign';
import Button from 'src/components/Button';
import classNames from 'classnames'

import { LazyLoadImage } from 'react-lazy-load-image-component';

const AboutUsSection = ({ mobile }) => {
    const items = [
        {
            key: 'improve',
            img: improveSrc,
            paragraph: <>Apriside <strong className="text-primary">helps you</strong> to manage operations, improve efficiency and  <strong className="text-primary">reduce project costs</strong>
            </>,
            smallParagraph: <>
                All of this with less time, without countless spreadsheets and with more transparency than ever before. You will have control. Always.
                </>
        },
        {
            key: 'cloud',
            img: cloudSrc,
            paragraph: <>
                We are a SaaS company which offers easy to use <strong className="text-primary">cloud-based Operations Management</strong>
            </>,
            smallParagraph: <>
                Application covering all aspects of the process such as planning, managing costs and logistics, governing risks registers, tracking & reporting operations and capturing lessons.
                </>
        },
        {
            key: 'finance',
            img: financeSrc,
            paragraph: <>
                Our integrated solution gives you better <strong className="text-primary">financial control</strong>
            </>,
            smallParagraph: <>
                Helps to make the informed decision, manage the learning process and gives you all the tools to boost performance and the analytic
            capabilities of your employees to a level not achievable by aggregation of fragmented applications.
            </>
        }
    ]

    const getNextSlideUrlPart = () => {
        if (typeof window === 'undefined') return '/'
        const number = window.location.hash.replace(/^\D+/g, '');

        if (number === '') return '/1'
        else if (+number >= items.length - 1) return ''
        else return `/${+number + 1}`
    }

    return <div className={classNames("AboutSection", {
        'AboutSection--is-mobile': mobile
    })}>
        {mobile ? (<>
            <div className="AboutSection__MobileHeader">
                <h3 className="Header AboutSection__Header">
                    About us
                </h3>
            </div>

            <Button to={`#about-us${getNextSlideUrlPart()}`} variant="wrapper" className="AboutSection__SwipeLeft">
                <SwipeSign label="Swipe left" gray direction="horizontal" />
            </Button>

            {items.map(item => <div key={item.key} className="slide">
                <div className="AboutSection__MobileSlideWrapper">
                    <AboutUsSectionItem
                        {...item}
                        key={item.key}
                    />
                </div>
            </div>
            )}
        </>)
            :
            (<>
                <h3 className="Header AboutSection__Header">
                    About us
                </h3>
                <div className="AboutSection__Cards">
                    {items.map(item => <AboutUsSectionItem
                        {...item}
                        key={item.key}
                    />)}
                </div>
            </>)}
    </div>
}

export default AboutUsSection



const AboutUsSectionItem = ({ img, paragraph, smallParagraph, mobile }) => {
    return <Card className="AboutSection__Cards__Element">
        {mobile ? <LazyLoadImage
            src={img}
            height={120}
            width={120}
            alt="We improve"
        /> : <LazyLoadImage
                src={img}
                height={120}
                width={120}
                className="AboutSection__Cards__Element__Image"
                alt="We improve"
            />}

        <p className="AboutSection__Cards__Element__MainParagraph">{paragraph}</p>
        <p className="small">{smallParagraph}</p>
    </Card>
}
