import React from 'react'
import './Navbar.scss'
import Logo from '../Logo'
import NavbarElement from './NavbarElement'
import NavbarElementsWrapper from './NavbarElementsWrapper'
import NavbarButton from './NavbarButton'
import classNames from 'classnames'
import { debounce } from 'src/utils'

const MENU_ANIMATION_TIME = 1500

const Navbar = ({ mobile, lastDirection, currentAnchor, smaller, dark, background }) => {
    const [open, setOpen] = React.useState(false)
    const rootRef = React.useRef()

    const removeAnimationClass = debounce(function() {
        rootRef && rootRef.current && rootRef.current.classList.remove('Navbar--is-going-bottom')           
    }, MENU_ANIMATION_TIME);
    
    if (!mobile && lastDirection === 'down' && rootRef && rootRef.current) {
        removeAnimationClass()
    }

    const closeMenu = () => mobile && setOpen(false)

    return (
        <nav ref={rootRef} className={classNames("Navbar", {
            'Navbar--is-smaller': smaller,
            'Navbar--is-dark': dark,
            'Navbar--is-mobile': mobile,
            'Navbar--is-open': open,
            'Navbar--has-light-bg': background === 'light',
            'Navbar--is-going-bottom': !mobile && lastDirection === 'down' && currentAnchor !== 'footer' 
        })}>
            {!mobile && <Logo to="#main" animated dark={dark} className="Navbar__Logo" />}

            <NavbarElementsWrapper open={open} setOpen={setOpen} mobile={mobile} dark={dark}>
                <NavbarElement
                    to="#main"
                    active={currentAnchor === 'main'}
                    dark={dark}
                    onClick={closeMenu}
                >Home</NavbarElement> 
                <NavbarElement
                    to="#about-us"
                    active={currentAnchor === 'about-us'}
                    dark={dark}
                    onClick={closeMenu}
                >About us</NavbarElement> 
                <NavbarElement
                    to="#mission"
                    active={currentAnchor === 'mission'}
                    dark={dark}
                    onClick={closeMenu}
                >Our mission</NavbarElement> 
                <NavbarElement
                    to="#products"
                    active={currentAnchor === 'products'}
                    dark={dark}
                    onClick={closeMenu}
                >Products</NavbarElement> 
                <NavbarElement
                    to="#customers"
                    active={currentAnchor === 'customers'}
                    dark={dark}
                    onClick={closeMenu}
                >Customers</NavbarElement> 
                <NavbarButton 
                    to="#contact"
                    active={['contact', 'footer'].includes(currentAnchor)}
                    dark={dark}
                    onClick={closeMenu}
                >Contact Us</NavbarButton>
            </NavbarElementsWrapper>             
        </nav>
    )
}

export default Navbar
