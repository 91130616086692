import React from 'react'
import classNames from 'classnames'

const NavigationDots = ({ currentAnchor }) => {
    return <ul id="fullPageDots" className={classNames("NavigationDots", {
        "NavigationDots--is-light": ['main', 'products'].includes(currentAnchor)
      })}>
        <li data-menuanchor="main" className="NavigationDots__Element active"><a href="#main"></a></li>
        <li data-menuanchor="about-us" className="NavigationDots__Element"><a href="#about-us"></a></li>
        <li data-menuanchor="mission" className="NavigationDots__Element"><a href="#mission"></a></li>
        <li data-menuanchor="products" className="NavigationDots__Element"><a href="#products"></a></li>
        <li data-menuanchor="customers" className="NavigationDots__Element"><a href="#customers"></a></li>
        <li data-menuanchor="contact" className={classNames("NavigationDots__Element", {
          "active": currentAnchor === 'footer'
        })}><a href="#contact"></a></li>
      </ul>
}

export default NavigationDots
